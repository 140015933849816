import React, { ElementType } from 'react';
import { Button, ButtonBaseProps, ButtonProps, LinkProps } from '@mui/material';
import getLinkProperties from '@/helpers/get-link-properties';
import { LinkTarget } from '@/types/linkTarget';

interface StandardButtonProps extends Pick<LinkProps, 'target'> {
  targetOverride?: LinkTarget,
  component?: ElementType,
}
function StandardButton({
  targetOverride = null,
  children,
  href,
  ...rest
}: StandardButtonProps & ButtonProps & ButtonBaseProps) {
  const { url, target } = getLinkProperties(href, targetOverride);

  return (
    <Button
      href={url}
      target={target}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default StandardButton;
