import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { Variant } from './LinkButton.enums';

// eslint-disable-next-line import/prefer-default-export
export const StyledButtonBase = styled(
  ButtonBase,
  { shouldForwardProp: (prop) => prop !== 'variant' },
)(({ theme, variant }: { theme?: Theme, variant: Variant }) => ({
  fontSize: theme.spacing(1.6),
  borderBottom: `${theme.spacing(0.2)} solid`,
  lineHeight: theme.spacing(2.4),
  '&:hover, &:focus': {
    ...(variant === Variant['red-hover'] ? { borderBottomColor: theme.palette.primary.main } : {}),
  },
  // Set to browser defaults
  '&.MuiButtonBase-root': {
    outline: '',
    WebkitTapHighlightColor: '',
    '-moz-appearance': '',
    '-webkit-appearance': '',
    '&::-moz-focus-inner': {
      borderStyle: '',
    },
  },
  '&.Mui-focusVisible': {
    outline: 'revert',
    outlineOffset: theme.spacing(0.2),
  },
}));
