import { useMemo } from 'react';
import { useGetTemplatesByCodesAndVersionsQuery } from '@/store/queries/v2/programs';
import { useGetAllProgramsByPersonIdQuery } from '@/store/queries/v2/student';
import { TransformedProgram, filterProgramsData, getTransformedProgramsData } from '@/store/helpers/programs';
import { ByIdObject, convertObjectArrayToByIdObject } from '@/helpers/object';
import { useSelector } from 'react-redux';

const useGetProgramsByIdHook = (personId: string) => {
  const {
    data: allPrograms = [],
    isFetching: isGetProgramsByIdFetching,
    isError: isGetProgramsByIdError,
  } = useGetAllProgramsByPersonIdQuery({ personId }, { skip: !personId });

  const transformedAllProgramsData: TransformedProgram[] = useMemo(() => {
    if (!isGetProgramsByIdFetching && !isGetProgramsByIdError) {
      return getTransformedProgramsData(allPrograms);
    }
    return [];
  }, [allPrograms, isGetProgramsByIdFetching, isGetProgramsByIdError]);

  const codesAndVersions = useMemo(() => transformedAllProgramsData?.map((
    program: { code: string, version: string },
  ) => ({ code: program.code, version: program.version })) || [], [transformedAllProgramsData]);

  const {
    data: programTemplates,
    isFetching: isGetTemplatesFetching,
    isError: isGetTemplatesError,
  } = useGetTemplatesByCodesAndVersionsQuery(
    { codesAndVersions },
    { skip: isGetProgramsByIdFetching || isGetProgramsByIdError || !codesAndVersions },
  );

  const selectedProgramId = useSelector((state: {
    session: {
      selectedProgramId: string,
    }
  }) => state.session.selectedProgramId);

  const data = useMemo(() => {
    if (
      isGetProgramsByIdError
      || isGetTemplatesError
      || isGetProgramsByIdFetching
      || isGetTemplatesFetching
    ) {
      return {};
    }

    const {
      primaryProgramId,
      programsListArray,
    } = filterProgramsData(transformedAllProgramsData, programTemplates);

    const programsList: ByIdObject = convertObjectArrayToByIdObject(programsListArray);

    return {
      primaryProgramId,
      selectedProgramId,
      programsList,
    };
  }, [
    isGetProgramsByIdError,
    isGetTemplatesError,
    isGetProgramsByIdFetching,
    isGetTemplatesFetching, transformedAllProgramsData,
    programTemplates,
    selectedProgramId,
  ]);

  return {
    data,
    isFetching: isGetProgramsByIdFetching || isGetTemplatesFetching,
    isError: isGetProgramsByIdError || isGetTemplatesError,
  };
};

export default useGetProgramsByIdHook;
