import { PropsWithChildren } from 'react';
import { Variant } from './LinkButton.enums';
import { StyledButtonBase } from './LinkButton.styles';

type LinkButtonProps = PropsWithChildren<{
  variant: string;
  [key: string]: any;
}>;
declare type LinkButtonVariant = keyof typeof Variant;

function LinkButton({ children, variant = 'default', ...rest }: LinkButtonProps) {
  return (
    <StyledButtonBase
      variant={Variant[variant as LinkButtonVariant]}
      focusRipple
      {...rest}
    >
      {children}
    </StyledButtonBase>
  );
}

export default LinkButton;
