import { toDateTime } from '@/myphoenix/utils/date-time-functions';
import { Section } from '@/types/api/learning';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';

interface ObjectWithId extends Object {
  id?: string;
  [key: string]: any;// this needs to be any to account for all possible values in objects
}

export interface ByIdObject {
  [key: string]: ObjectWithId;
}

export const convertObjectArrayToByIdObject = (array: ObjectWithId[]) => {
  let byId: ByIdObject = {};
  if (array && Array.isArray(array)) {
    array.forEach((item) => {
      const { id } = item;
      if (id) {
        byId = {
          ...byId,
          [id]: item,
        };
      }
    });
  }
  return { byId };
};

export const convertToByMembershipIdObject = (
  memberships: StudentCourseMembership[],
  courses: Section[],
) => {
  let byMembershipId: ByIdObject = {};
  if (memberships && Array.isArray(memberships) && courses && Array.isArray(courses)) {
    memberships.forEach((membership) => {
      const { id } = membership;
      const course = courses.find((courseData: Section) => courseData.id === membership.sourceId);
      if (id) {
        byMembershipId = {
          ...byMembershipId,
          [id]: {
            membershipId: membership.id,
            grade: membership.grade ? membership.grade : '',
            sectionId: membership.sourceId ? membership.sourceId : '',
            statusSubCode: membership.statusSubCode || membership.status,
            startDate: toDateTime(membership.startDate),
            endDate: toDateTime(membership.endDate),
            daTermCode: membership.daAcademicYearTerm ? membership.daAcademicYearTerm.termCode : '',
            offeringType: membership.offeringType,
            defaultCredits: course.defaultCredits,
            description: course.description,
            templateCode: course.templateCode,
            title: course.title,
            type: course.type,
          },
        };
      }
    });
  }
  return { byMembershipId };
};
